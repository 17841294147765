import { types } from "../types/types"

const initialState = {
    loading: false,
    msgError: null,
    data: null,
    userFilters:{filter:{tableFilter:'',columnFilter:{}}},
    facturaFilters:{filter:{tableFilter:'',columnFilter:{}}},
    NDFilters:{filter:{tableFilter:'',columnFilter:{}}},
    ReposFilters:{filter:{tableFilter:'',columnFilter:{}}},
}

export const uiReducer = (state = initialState, action) => {
    switch(action.type){
        case types.uiSetError:
            return {
                ...state,
                msgError: action.payload
            }
        case types.uiRemoveError:
            return {
                ...state,
                msgError: null
            }
        case types.uiStartLoading:
            return {
                ...state,
                loading: true
            }
        case types.uiFinishLoading:
            return {
                ...state,
                loading: false
            }
        case types.uiParameters:
            return {
                ...state,
                data: action.payload.data
            }
        case types.uiSetUserFilters:
            return {
                ...state,
                userFilters:{
                    filter: action.payload.data 
                }
            }
        case types.uiSetFacturasFilters:
            return {
                ...state,
                facturaFilters:{
                    filter: action.payload.data 
                }
            }
        case types.uiSetNDFilters:
            return {
                ...state,
                NDFilters:{
                    filter: action.payload.data 
                }
            }
        case types.uiSetReposFilters:
            return {
                ...state,
                ReposFilters:{
                    filter: action.payload.data 
                }
            }
    
        default:
            return state;
    }
}
/*
const initState = {
    sidebarShow: 'responsive'
  };

export const changeState = (state = initState, { type, ...rest }) => {
    switch (type) {
      case 'set':
        return {...state, ...rest }
      default:
        return state;
    }
  }*/