import { types } from "../types/types";

const login = (user, token, avatar, email, uenId, sociedadCO, roles, cecos) => ({
    type:types.login,
    payload: {
        user, 
        token, 
        avatar,
        email,
        uenId,
        sociedadCO,
        roles,
        cecos,
    }
})

const logout = () => ({
    type:types.logout
})

export const startGoogleLogin = (user, access_token, avatar, email, uenId, sociedadCO, roles, cecos) => {
    return (dispatch) => {
        dispatch(login(user, access_token, avatar, email, uenId, sociedadCO, roles, cecos));
    }
}

export const startLogout = () => {
    return (dispatch) => {
        dispatch(logout());
    }
}
