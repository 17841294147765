import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types';

export const PrivateRoute = ({
    isAuthenticated, component:Component, ...rest
}) => {

    if(rest.location.pathname !== '/'){
        localStorage.setItem('lastPath',rest.location.pathname);
    }
    
    return (
        <Route {...rest} render={
            (props) => (
                (isAuthenticated) 
                ? <Component { ...props} />  
                : <Redirect to="/login" />
            )}
            />
    )
}

PrivateRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    component: PropTypes.func.isRequired
}