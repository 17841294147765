import React, { Component } from 'react';
import { GlobalContext } from './context/GlobalContext';
import {AppRouter} from './routesHandler/AppRouter';

// Revisar "/routesHandler/AppRouter" para poder manejar con estados las rutas publicas y privadas
class App extends Component {

  
  render() {

    const globalVars = {
      BACKEND_URL: process.env.REACT_APP_BACKEND_URL,
      GOOGLE_CALLBACK: process.env.REACT_APP_GOOGLE_CALLBACK,
      API_VTI_ENDPOINT: process.env.REACT_APP_VTI_ENDPOINT
    };
    
    return (
      <GlobalContext.Provider
      value={globalVars} >

          <AppRouter />

      </GlobalContext.Provider>
    );
  }
}

export default App;
