import { sygnet } from './sygnet'
import { logo } from './logo'
import { logoNegative } from './logo-negative'

import {
  cibSkype,
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibStackoverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay,
  cibCcAmex,
  cilClipboard,
  cibTravisci,
  cibSap,
} from '@coreui/icons'
import {
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl
} from '@coreui/icons'
import {
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowBottom,
  cilArrowRight,
  cilArrowTop,
  cilAsterisk,
  cilBan,
  cilBank,
  cilBarcode,
  cilBaseball,
  cilBasket,
  cilBell,
  cilBook,
  cilBold,
  cilBookmark,
  cilCamera,
  cilCarAlt,
  cilCart,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilCouch,
  cilDevices,
  cilGarage,
  cilBoatAlt,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilCircle,
  cilCheckCircle,
  cilCode,
  cilCog,
  cilCommentSquare,
  cilCreditCard,
  cilCursor,
  cilCursorMove,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEuro,
  cilGlobeAlt,
  cilGrid,
  cilFile,
  cilFindInPage,
  cilFullscreen,
  cilFullscreenExit,
  cilGraph,
  cilHome,
  cilInbox,
  cilInfo,
  cilIndentDecrease,
  cilIndentIncrease,
  cilInputPower,
  cilItalic,
  cilJustifyCenter,
  cilJustifyLeft,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilListNumbered,
  cilListRich,
  cilLink,
  cilLocationPin,
  cilLockLocked,
  cilLockUnlocked,
  cilColumns,
  cilGift,
  cilImageBroken,
  cilMagnifyingGlass,
  cilMap,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPaperclip,
  cilPaperPlane,
  cilPeople,
  cilPen,
  cilPhone,
  cilPrint,
  cilPuzzle,
  cilReload,
  cilSave,
  cilSend,
  cilScrubber,
  cilSettings,
  cilCash,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilStorage,
  cilSun,
  cilTags,
  cilTask,
  cilTrash,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
  cilX,
  cilTruck,
  cilFactory,
  cilXCircle,
  cilWarning,
  cilWallet,
  cilWallpaper,
  cilTerminal,
  cilTag,
  cilSync,
  cilRoom,
  cilRunning,
  cilPin,
  cilPenAlt,
  cilNoteAdd,
  cilFilterSquare,
  cilFilterX,
  cilLoop,
  cilLoop1,
  cilLevelDown,
  cilLinkBroken,
  cilVector,
  cilSitemap,
  cilPlaylistAdd,
  cilFeaturedPlaylist,
  cilPlus,
  cilMinus,
  cilTransfer,
  cilMoney,
  cilBalanceScale,
  cilUserPlus,
  cilCalendarCheck,
  cilMenu,
  cilImage,
  cilColorBorder,
  cilPencil,
  cilChartLine,
  cilAddressBook,
  cilBuilding,
  cilCheckAlt,
  cilCloudUpload,
  cilArrowLeft,
  cilArrowThickFromRight,
  cilArrowThickToRight,
  cilAccountLogout,
  cilFaceDead,
  cilBug,
  cilFax,
  cilWindow,
  cilWindowRestore,
  cilDataTransferDown,
  cilZoomIn,
  cilZoomOut
} from '@coreui/icons'

export const icons = Object.assign({}, {
  sygnet,
  logo,
  logoNegative
}, {
  cilClipboard,
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowBottom,
  cilArrowRight,
  cilArrowTop,
  cilAsterisk,
  cilBan,
  cilBank,
  cilBarcode,
  cilBasket,
  cilBaseball,
  cilBell,
  cilBook,
  cilBold,
  cilBookmark,
  cilCalculator,
  cilCalendar,
  cilCamera,
  cilCarAlt,
  cilCart,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilCouch,
  cilDevices,
  cilGarage,
  cilBoatAlt,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilCircle,
  cilCheckCircle,
  cilCode,
  cilCog,
  cilCommentSquare,
  cilCreditCard,
  cilCursor,
  cilCursorMove,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEuro,
  cilGlobeAlt,
  cilGrid,
  cilFile,
  cilFindInPage,
  cilFullscreen,
  cilFullscreenExit,
  cilGraph,
  cilHome,
  cilInbox,
  cilInfo,
  cilIndentDecrease,
  cilIndentIncrease,
  cilInputPower,
  cilItalic,
  cilJustifyCenter,
  cilJustifyLeft,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilListNumbered,
  cilListRich,
  cilLink,
  cilLocationPin,
  cilLockLocked,
  cilLockUnlocked,
  cilColumns,
  cilGift,
  cilImageBroken,
  cilMagnifyingGlass,
  cilMap,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPaperclip,
  cilPaperPlane,
  cilPeople,
  cilPen,
  cilPhone,
  cilPrint,
  cilPuzzle,
  cilSave,
  cilSend,
  cilReload,
  cilScrubber,
  cilSettings,
  cilCash,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilStorage,
  cilSun,
  cilTags,
  cilTask,
  cilTrash,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
  cilX,
  cilTruck,
  cilFactory,
  cilXCircle,
  cilWarning,
  cilWallet,
  cilWallpaper,
  cilTerminal,
  cilTag,
  cilSync,
  cilRoom,
  cilRunning,
  cilPin,
  cilPenAlt,
  cilNoteAdd,
  cilFilterSquare,
  cilFilterX,
  cilLoop,
  cilLoop1,
  cilLevelDown,
  cilLinkBroken,
  cilVector,
  cilSitemap,
  cilPlaylistAdd,
  cilFeaturedPlaylist,
  cilPlus,
  cilMinus,
  cilTransfer,
  cilMoney,
  cilBalanceScale,
  cilUserPlus,
  cilCalendarCheck,
  cilImage,
  cilMenu,
  cilColorBorder,
  cilPencil,
  cilChartLine,
  cilAddressBook,
  cilBuilding,
  cilCheckAlt,
  cilCloudUpload,
  cilArrowLeft,
  cilArrowThickFromRight,
  cilArrowThickToRight,
  cilAccountLogout,
  cilFaceDead,
  cilBug,
  cilFax,
  cilWindow,
  cilWindowRestore,
  cilDataTransferDown,
  cilZoomIn,
  cilZoomOut,
  
}, {
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl
}, {
  cibTravisci,
  cibSkype,
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibStackoverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay,
  cibCcAmex,
  cibSap,
})
