// actions.js
export const SET_DATES = 'SET_DATES';

export const setDates = (startDate, endDate,data) => ({
  type: SET_DATES,
  payload: { startDate, endDate,data},
});



